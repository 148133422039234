import React from 'react'
import { Link } from 'gatsby'
import { Columns } from 'react-bulma-components'
import styled from '@emotion/styled'
import Styles from '../styles/index.module.scss'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import Content from '../components/Content'
import DocStyles from '../styles/doc.module.scss'

const Topic = styled(Columns.Column)`
  & > a {
    background-color: #f5f6f7;
    color: #023895;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    display: block;
    font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow',
      sans-serif;
    text-transform: uppercase;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 10px;
    font-size: 18px;
  }
  & > a:visited {
    color: #023895;
  }
  & > a:hover {
    text-decoration: none;
  }
`

const HomeBlock = styled(Columns.Column)`
  & > a:hover {
    text-decoration: none;
  }
`

const BlogIndex = ({ location }) => {
  return (
    <>
      <Nav location={location} />
      <div className={DocStyles.DocWrapper}>
        <Content>
          <SEO title="Home" />
          <h1 style={{ textTransform: 'uppercase' }}>
            Welcome to WEGnology Docs!
          </h1>
          <p>
            We're happy you're here! Getting familiar with the WEGnology product
            is easy. Get started below.
          </p>

          <Columns>
            <HomeBlock>
              <Link to="/getting-started/what-is-wegnology/">
                <div className={Styles.docsContent}>
                  <h2>What is WEGnology?</h2>
                  <p>
                    WEGnology is an easy-to-use and powerful enterprise IoT
                    platform.
                  </p>
                </div>
              </Link>
            </HomeBlock>
            <HomeBlock>
              <Link to="/getting-started/walkthrough">
                <div className={Styles.docsContent}>
                  <h2>Walkthrough</h2>
                  <p>
                    Jump right in and get the basics of using the WEGnology
                    platform.
                  </p>
                </div>
              </Link>
            </HomeBlock>
            <HomeBlock>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://learn.weg.net/lms/#/home/curso/?secao=49&idcatalogo=42&idcurso=55&tipo=&buscaSimples=true"
              >
                <div className={Styles.docsContent}>
                  <h2>University</h2>
                  <p>Access the EAD training - IoT WEGnology Platform.</p>
                </div>
              </a>
            </HomeBlock>
            <HomeBlock size={12}>
              <a href="/workflow-lab/lab/">
                <img
                  style={{
                    border: 'none',
                    width: '97%',
                  }}
                  src="/images/lab-banner.png"
                  alt="The WEGnology Workflow Lab"
                />
              </a>
            </HomeBlock>
          </Columns>
          <h2 style={{ textTransform: 'uppercase' }}>Choose a Topic</h2>
          <Columns>
            <Topic size={2}>
              <Link to="/getting-started/what-is-wegnology/">
                Getting Started
              </Link>
            </Topic>
            <Topic size={2}>
              <Link to="/user-accounts/overview/">User Accounts</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/organizations/overview/">Organizations</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/applications/overview/">Applications</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/experiences/overview/">Experiences</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/edge-compute/overview/">Edge Compute</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/devices/overview/">Devices</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/notebooks/overview/">Notebooks</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/dashboards/overview/">Dashboards</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/data-tables/overview/">Data Tables</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/workflows/overview/">Workflows</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/cli/overview/">WEGnology CLI</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/mqtt/overview/">MQTT</Link>
            </Topic>
            <Topic size={2}>
              <Link to="/rest-api/overview/">Rest API</Link>
            </Topic>
          </Columns>
        </Content>
      </div>
    </>
  )
}

export default BlogIndex
